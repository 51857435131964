/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";

function GameBackdrop(props: { children: React.ReactNode }) {
  const [stripeHue, setStripeHue] = useState(130);
  useEffect(() => {
    const intervalId = setInterval(
      () => setStripeHue((prev) => (prev + 1) % 360),
      3000
    );
    return () => clearInterval(intervalId);
  }, [setStripeHue]);

  const backdropStyles = css`
    --col-bg-stripe-1: hsla(0, 100%, 100%, 0);
    --col-bg-stripe-2: hsla(${stripeHue}, 100%, 85%, 1);

    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: white;
    background-image: linear-gradient(
      135deg,
      var(--col-bg-stripe-1) 0%,
      var(--col-bg-stripe-1) 25%,
      var(--col-bg-stripe-2) 25%,
      var(--col-bg-stripe-2) 50%,
      var(--col-bg-stripe-1) 50%,
      var(--col-bg-stripe-1) 75%,
      var(--col-bg-stripe-2) 75%,
      var(--col-bg-stripe-2) 100%
    );
    background-size: 100px 100px;
  `;

  return <div css={backdropStyles}>{props.children}</div>;
}

export default GameBackdrop;
