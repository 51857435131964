import { Global, css } from "@emotion/react";

const globalStyles = css`
  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
  }

  :root {
  }
`;

function Theme() {
  return <Global styles={globalStyles} />;
}

export default Theme;
