import styled from "@emotion/styled";

import PipeTile, { PipeTileDef } from "./PipeTile";
import EdgeTile from "./EdgeTile";
import CornerTile from "./CornerTile";

const BoardTable = styled("table")`
  --col-bg-edge: hsla(0, 0%, 90%, 1);
  --col-bg-tile: hsla(270, 70%, 60%, 1);

  --col-edge-tile: hsla(0, 100%, 100%, 1);

  --col-path-stroke: hsla(0, 100%, 100%, 1);
  --col-path-outline: hsla(0, 100%, 100%, 1);
  --col-path-default: hsla(0, 0%, 20%, 1);
  --col-path-active: hsla(150, 60%, 50%, 1);

  margin: 0;
  padding: 0;
  border-collapse: collapse;

  td {
    margin: 0;
    padding: 0;
    line-height: 0;
  }
`;

type PipeBoardProps = {
  boardWidth?: number;
  boardHeight?: number;
  pathStart?: [number, number];
  tileDefs: Array<PipeTileDef | null>;
};

function PipeBoard(props: PipeBoardProps) {
  const tileWidth = 100;
  const tileHeight = tileWidth;
  const edgeWidth = 30;
  const edgeHeight = edgeWidth;

  const {
    boardWidth = 3,
    boardHeight = 4,
    pathStart = [0, 0],
    tileDefs,
  } = props;
  const [startTile, startPoint] = pathStart;

  function tileAt(r: number, c: number) {
    const i = r * boardWidth + c;
    const tileDef = tileDefs[i];
    return tileDef !== null ? (
      <PipeTile tileDef={tileDef} tileWidth={tileWidth} />
    ) : (
      <Spacer w={tileWidth} h={tileHeight} />
    );
  }

  return (
    <BoardTable>
      <tbody>
        <tr>
          <td>
            <CornerTile tileWidth={edgeHeight} />
          </td>
          {Array.from({ length: boardWidth }, (_, c) => {
            const adjTileIndex = c;
            let start = -1;
            if (adjTileIndex === startTile) {
              if (startPoint === 0 || startPoint === 1) {
                start = startPoint;
              }
            }

            return (
              <td key={`rHc${c}`}>
                <EdgeTile
                  tileWidth={tileWidth}
                  tileHeight={edgeHeight}
                  start={start}
                />
              </td>
            );
          })}
          <td>
            <CornerTile tileWidth={edgeHeight} rotation={1} />
          </td>
        </tr>
        {Array.from({ length: boardHeight }, (_, r) => (
          <tr key={`r${r}`}>
            <td>
              <EdgeTile
                tileWidth={edgeHeight}
                tileHeight={tileWidth}
                rotation={3}
              />
            </td>
            {Array.from({ length: boardWidth }, (_, c) => (
              <td key={`rHc${c}`}>{tileAt(r, c)}</td>
            ))}
            <td>
              <EdgeTile
                tileWidth={edgeHeight}
                tileHeight={tileWidth}
                rotation={1}
              />
            </td>
          </tr>
        ))}
        <tr>
          <td>
            <CornerTile tileWidth={edgeHeight} rotation={3} />
          </td>
          {Array.from({ length: boardWidth }, (_, c) => (
            <td key={`rHc${c}`}>
              <EdgeTile
                tileWidth={tileWidth}
                tileHeight={edgeHeight}
                rotation={2}
              />
            </td>
          ))}
          <td>
            <CornerTile tileWidth={edgeHeight} rotation={2} />
          </td>
        </tr>
      </tbody>
    </BoardTable>
  );
}

export default PipeBoard;

function Spacer(props: { w: number; h: number }) {
  const { w, h } = props;
  return (
    <div
      style={{
        width: w - 2,
        height: h - 2,
        margin: 1,
        backgroundColor: "#f8f8f8",
        border: "1px dashed #ddd",
      }}
    />
  );
}
