/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";

export type PipePathDef = {
  points: [number, number];
  highlight?: boolean;
  order?: number;
};

export type PipeTileDef = {
  paths: PipePathDef[];
  rotation?: number;
};

type PipeTileProps = {
  tileDef: PipeTileDef;
  tileWidth?: number;
};

function PipeTile(props: PipeTileProps) {
  const { tileDef, tileWidth = 100 } = props;
  const { rotation = 0 } = tileDef;
  const transform = `rotate(${rotation * 90})`;
  return (
    <TileSVG width={tileWidth} height={tileWidth} viewBox="-50 -50 100 100">
      <g transform={transform}>
        <TileBG
          x={-50}
          y={-50}
          width={100}
          height={100}
          strokeWidth="2"
          stroke="#fff"
        />
        {tileDef.paths.map((p, i) => (
          <TilePath key={i} points={p.points} highlight={p.highlight} />
        ))}
        <circle cx={0} cy={-40} r={2} fill="hsla(0, 100%, 100%, 0.25)" />
      </g>
    </TileSVG>
  );
}

export default PipeTile;

const TileSVG = styled("svg")`
  .guides {
    stroke-width: 1;
    stroke: #eee;
    stroke-dasharray: 3;
    display: none;
  }

  .stubs {
    stroke: #330000;
    stroke-width: 3;
    fill: none;
  }

  .paths {
    stroke: #333333;
    stroke-width: 4;
    fill: none;
  }

  .path {
    --col-path: var(--col-path-default);
    &.highlight {
      --col-path: var(--col-path-active);
    }

    path:nth-child(1) {
      stroke-width: 10;
      stroke: var(--col-tile-bg);
      fill: none;
    }
    path:nth-child(2) {
      stroke-width: 7;
      stroke: #ffffff;
      fill: none;
    }
    path:nth-child(3) {
      stroke-width: 5;
      stroke: var(--col-path);
      fill: none;
    }
  }
`;

const TileBG = styled("rect")`
  fill: var(--col-bg-tile);
`;

/*
Coords

  0 1
7 +-+ 2
6 +-+ 3
  5 4

  */

// Original paths
// const pathModels = [
//   "M -15,-49 V -15", // unknown
//   "M -15,-49 L -15,-42 C -15,-22 15,-22 15,-42 L 15,-49", // tight180
//   "M -15,-49 V -49 C -15,-30 0,-15 30,-15 H 49", // wideHook
//   "M -15,-49 L -15,-49 C -15,-20 20,15 49,15 L 49,15", // wide90
//   "M -15,-49 L -15,-40 C -15,-15 15,15 15,40 L 15,49", // kinked
//   "M -15,-49 L -15,49", // straight
//   "M -15,-49 V -30 C -15,0 -30,15 -49,15 L -49,15", // longHook
//   "M -15,-49 L -15,-49 C -15,-27 -27,-15 -49,-15 L -49,-15", // tight90
// ];

// Simpler paths (removes straight segments at start/end)
const pathModels = [
  "M -15,-49 V -15", // unknown
  "M -15,-49 C -15,-22 15,-22 15,-49", // tight180
  "M -15,-49 C -15,-30 0,-15 49,-15", // wideHook
  "M -15,-49 C -15,-20 20,15 49,15", // wide90
  "M -15,-49 C -15,-15 15,15 15,49", // kinked
  "M -15,-49 L -15,49", // straight
  "M -15,-49 C -15,0 -30,15 -49,15", // longHook
  "M -15,-49 C -15,-27 -27,-15 -49,-15", // tight90
];

const TilePath = (props: PipePathDef) => {
  const { points, highlight = Math.random() > 0.5 } = props;

  const pointA = Math.min(points[0], points[1]);
  const pointB = Math.max(points[0], points[1]);
  const side = Math.floor(pointA / 2);
  const parity = pointA % 2;
  const offset = (8 + pointB - pointA) % 8;

  const pathIndex = parity === 1 ? 8 - offset : offset;
  const path = pathModels[pathIndex];

  // @NB: Transforms are applied right to left; not sure if this logic actually works the way I think it does???
  const rotation = `rotate(${side * 90})`;
  const scale =
    parity === 0
      ? "scale(1,1)"
      : side % 2 === 0
      ? "scale(-1,1)"
      : "scale(1,-1)";
  const transform = `${scale} ${rotation}`;

  const classNames = ["path", highlight && "highlight"]
    .filter(Boolean)
    .join(" ");

  return (
    <g className={classNames}>
      <path d={path} transform={transform} />
      <path d={path} transform={transform} />
      <path d={path} transform={transform} />
    </g>
  );
};
