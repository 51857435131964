/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";

type EdgeTileProps = {
  tileWidth?: number;
  tileHeight?: number;
  rotation?: number;
  start?: number;
};

function EdgeTile(props: EdgeTileProps) {
  const { tileWidth = 100, tileHeight = 30, rotation = 0, start = -1 } = props;
  const transform = `rotate(${rotation * 90})`;
  const viewBox = rotation % 2 === 0 ? "-50 -15 100 30" : "-15 -50 30 100";
  return (
    <TileSVG width={tileWidth} height={tileHeight} viewBox={viewBox}>
      <g transform={transform}>
        <path className="bg" d="M -50,-15 V 14 H 50 V -15 Z" />
        <circle cx={0} cy={5} r={2} fill="hsla(0, 100%, 0%, 0.1)" />
        {start >= 0 && (
          <g className="start" transform={start % 2 === 0 ? "" : "scale(-1,1)"}>
            <circle cx={-15} cy={0} r={8} />
            <path d="M -15,0 V 14" />
            <circle cx={-15} cy={0} r={7} />
            <path d="M -15,0 V 14" />
          </g>
        )}
      </g>
    </TileSVG>
  );
}

export default EdgeTile;

const TileSVG = styled("svg")`
  --col-tile-bg: hsla(0, 0%, 90%, 1);

  .bg {
    fill: var(--col-bg-edge);
  }

  .start circle:nth-of-type(1) {
    fill: var(--col-path-outline);
  }
  .start circle:nth-of-type(2) {
    fill: var(--col-path-active);
  }
  .start path:nth-of-type(1) {
    stroke: var(--col-path-outline);
    stroke-width: 7;
  }
  .start path:nth-of-type(2) {
    stroke: var(--col-path-active);
    stroke-width: 5;
  }
`;
