import GameBackdrop from "./GameBackdrop";
import GameStage from "../GameStage";

const PipesGame = () => {
  return (
    <GameBackdrop>
      <GameStage />
    </GameBackdrop>
  );
};

export default PipesGame;
